<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-alert
          v-if="loading"
          border="start"
          variant="tonal"
          color="primary"
          icon="mdi-progress-download"
          :title="$t('summary.statuses.account')"
          :class="accountCanTrade ? 'rounded-b-0' : ''"
        >
          <strong>{{ $t('statuses.verification.loading') }}</strong>
        </v-alert>
        <v-alert
          v-if="!loading"
          border="start"
          variant="tonal"
          :color="verificationStatusColor"
          :icon="verificationStatusIcon"
          :title="$t('summary.statuses.account')"
          :class="accountCanTrade ? 'rounded-b-0' : ''"
        >
          <strong>{{ verificationStatusText }}</strong>
        </v-alert>
        <v-alert
          v-if="accountCanTrade"
          border="start"
          variant="tonal"
          color="success"
          icon="mdi-star-shooting"
          :text="$t('summary.statuses.canTrade', { brand })"
          class="rounded-t-0"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card color="surface" flat>
          <template v-for="(item, index) in items" :key="index">
            <v-list-item nav :to="item.link" :title="item.title">
              <template #prepend>
                <v-badge
                  :color="item.status.color"
                  :model-value="true"
                  location="bottom start"
                  :offset-y="5"
                  :offset-x="5"
                >
                  <template #badge>
                    <v-icon v-if="!loading">{{ item.status.icon }}</v-icon>
                    <v-progress-circular v-else indeterminate size="9" width="1" color="white" />
                  </template>
                  <v-avatar size="36" color="transparent">
                    <v-icon color="secondary">{{ item.icon }}</v-icon>
                  </v-avatar>
                </v-badge>
              </template>
              <template #append>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-list-item>
            <v-divider v-if="index < items.length - 1" />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject, computed, onMounted } from 'vue'
import { useStatusStore } from '~/stores/status'
import type { Bus } from '~/libs/bus'
import type { Axios } from 'axios'

export default defineComponent({
  name: 'PartialsNav',
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const localePath = useLocalePath()
    const { t } = useI18n({ useScope: 'global' })
    const { getColorFromStatus, getIconFromStatus } = useStatusFunctions()
    const router = useRouter()
    const api = inject<Axios>('api')!
    const bus = inject<Bus>('bus')!
    const statusStore = useStatusStore()
    const loading = computed(() => statusStore.loaded !== true)
    const accountStatus = computed(() => statusStore.accountStatus)
    const accountCanTrade = computed(() => statusStore.accountCanTrade)
    const verificationStatusText = computed(() => {
      const key = `statuses.verification.${accountStatus.value}`
      return t(key)
    })
    const verificationStatusColor = computed(() =>
      getColorFromStatus(accountStatus.value, accountCanTrade.value)
    )
    const verificationStatusIcon = computed(() =>
      getIconFromStatus(accountStatus.value, accountCanTrade.value)
    )
    const rsaIdStatus = computed(() => statusStore.rsaIdStatus)
    const poiStatus = computed(() => statusStore.poiStatus)
    const porStatus = computed(() => statusStore.porStatus)
    const questionnaireStatus = computed(() => statusStore.questionnaireStatus)
    const items = computed(() => {
      return [
        {
          title: t('evidences.rsaId.title'),
          icon: 'mdi-card-account-details',
          status: {
            value: rsaIdStatus.value,
            color: getColorFromStatus(rsaIdStatus.value),
            icon: getIconFromStatus(rsaIdStatus.value),
          },
          link: localePath({ name: 'national-id' }),
        },
        {
          title: t('evidences.poi.title'),
          icon: 'mdi-account-card',
          status: {
            value: poiStatus.value,
            color: getColorFromStatus(poiStatus.value),
            icon: getIconFromStatus(poiStatus.value),
          },
          link: localePath({ name: 'evidence-poi' }),
        },
        {
          title: t('evidences.por.title'),
          icon: 'mdi-home-city',
          status: {
            value: porStatus.value,
            color: getColorFromStatus(porStatus.value),
            icon: getIconFromStatus(porStatus.value),
          },
          link: localePath({ name: 'evidence-por' }),
        },
        {
          title: t('evidences.questionnaire.title'),
          icon: 'mdi-script-text',
          status: {
            value: questionnaireStatus.value,
            color: getColorFromStatus(questionnaireStatus.value),
            icon: getIconFromStatus(questionnaireStatus.value),
          },
          link: localePath({ name: 'questionnaire' }),
        },
      ]
    })
    const brand = computed(() => runtimeConfig.public.brand)
    onMounted(() => {
      statusStore.fetch(api)
    })
    router.beforeEach(() => {
      statusStore.fetch(api)
    })
    router.afterEach(() => {
      nextTick(() => {
        statusStore.fetch(api)
      })
    })
    bus.on('identity:logout', () => statusStore.onLogout(), { local: true })
    bus.on('identity:login', () => statusStore.fetch(api), { local: true })
    return {
      brand,
      loading,
      items,
      accountCanTrade,
      verificationStatusText,
      verificationStatusColor,
      verificationStatusIcon,
    }
  },
})
</script>
